/* Styles for the custom select */
.custom-option {
    display: flex;
    align-items: center;
    padding: 6px 12px;
  }
  .custom-select-value {
    display: flex;
    align-items: center;
    padding: 0px 3px;
  }
  
  .icon {
    margin-right: 10px;
    /* Add icon-specific styling here */
  }
  