/* From Uiverse.io by marcelodolza */
.button {
    --primary: #ff5569;
    --neutral-1: hsl(130, 55%, 64%);
    --neutral-2: #dce9f5;


    /* --neutral-1: hsl(220, 35%, 72%);
    --neutral-2: #dce9f5; */
    --radius: 14px;

    cursor: pointer;
    border-radius: var(--radius);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: none;
    box-shadow: 0 0.5px 0.5px 1px rgba(255, 255, 255, 0.2),
        0 10px 20px rgba(0, 0, 0, 0.2), 0 4px 5px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease;
    min-width: 200px;
    padding: 20px;
    height: 68px;
    font-family: "Galano Grotesque", Poppins, Montserrat, sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
}

.button:hover {
    transform: scale(1.02);
    box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.3),
        0 15px 30px rgba(0, 0, 0, 0.3), 0 10px 3px -3px rgba(0, 0, 0, 0.04);
}

.button:active {
    transform: scale(1);
    box-shadow: 0 0 1px 2px rgba(255, 255, 255, 0.3),
        0 10px 3px -3px rgba(0, 0, 0, 0.2);
}

.button:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: var(--radius);
    border: 2.5px solid transparent;
    background: linear-gradient(var(--neutral-1), var(--neutral-2)) padding-box,
        linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.45)) border-box;
    z-index: 0;
    transition: all 0.4s ease;
}

.button:hover::after {
    transform: scale(1.05, 1.1);
    box-shadow: inset 0 -1px 3px 0 rgba(255, 255, 255, 1);
}

.button::before {
    content: "";
    inset: 7px 6px 6px 6px;
    position: absolute;
    background: linear-gradient(to top, var(--neutral-1), var(--neutral-2));
    border-radius: 30px;
    filter: blur(0.5px);
    z-index: 2;
}

.state p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.state .icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: scale(1.25);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.state .icon svg {
    overflow: visible;
}

/* Outline */
.outline {
    position: absolute;
    border-radius: inherit;
    overflow: hidden;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.4s ease;
    inset: -2px -3.5px;
}

.outline::before {
    content: "";
    position: absolute;
    inset: -100%;
    background: conic-gradient(from 180deg,
            transparent 60%,
            white 80%,
            transparent 100%);
    animation: spin 2s linear infinite;
    animation-play-state: paused;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.button:hover .outline {
    opacity: 1;
}

.button:hover .outline::before {
    animation-play-state: running;
}

/* Letters */
.state p span {
    display: block;
    opacity: 0;
    animation: slideDown 0.8s ease forwards calc(var(--i) * 0.03s);
}

.button:hover p span {
    opacity: 1;
    animation: wave 0.5s ease forwards calc(var(--i) * 0.02s);
}

.button:focus p span {
    opacity: 1;
    animation: disapear 0.6s ease forwards calc(var(--i) * 0.03s);
}

@keyframes wave {
    30% {
        opacity: 1;
        transform: translateY(4px) translateX(0) rotate(0);
    }

    50% {
        opacity: 1;
        transform: translateY(-3px) translateX(0) rotate(0);
        color: var(--primary);
    }

    100% {
        opacity: 1;
        transform: translateY(0) translateX(0) rotate(0);
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-20px) translateX(5px) rotate(-90deg);
        color: var(--primary);
        filter: blur(5px);
    }

    30% {
        opacity: 1;
        transform: translateY(4px) translateX(0) rotate(0);
        filter: blur(0);
    }

    50% {
        opacity: 1;
        transform: translateY(-3px) translateX(0) rotate(0);
    }

    100% {
        opacity: 1;
        transform: translateY(0) translateX(0) rotate(0);
    }
}

@keyframes disapear {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translateX(5px) translateY(20px);
        color: var(--primary);
        filter: blur(5px);
    }
}

/* Plane */
.state--default .icon svg {
    animation: land 0.6s ease forwards;
}

.button:hover .state--default .icon {
    transform: rotate(45deg) scale(1.25);
}

.button:focus .state--default svg {
    animation: takeOff 0.8s linear forwards;
}

.button:focus .state--default .icon {
    transform: rotate(0) scale(1.25);
}

@keyframes takeOff {
    0% {
        opacity: 1;
    }

    60% {
        opacity: 1;
        transform: translateX(70px) rotate(45deg) scale(2);
    }

    100% {
        opacity: 0;
        transform: translateX(160px) rotate(45deg) scale(0);
    }
}

@keyframes land {
    0% {
        transform: translateX(-60px) translateY(30px) rotate(-50deg) scale(2);
        opacity: 0;
        filter: blur(3px);
    }

    100% {
        transform: translateX(0) translateY(0) rotate(0);
        opacity: 1;
        filter: blur(0);
    }
}

/* Contrail */
.state--default .icon:before {
    content: "";
    position: absolute;
    top: 50%;
    height: 2px;
    width: 0;
    left: -5px;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.5));
}

.button:focus .state--default .icon:before {
    animation: contrail 0.8s linear forwards;
}

@keyframes contrail {
    0% {
        width: 0;
        opacity: 1;
    }

    8% {
        width: 15px;
    }

    60% {
        opacity: 0.7;
        width: 80px;
    }

    100% {
        opacity: 0;
        width: 160px;
    }
}

/* States */
.state {
    padding-left: 29px;
    z-index: 2;
    display: flex;
    position: relative;
}

.state--default span:nth-child(6) {
    margin-right: 5px;
}


.state--sent span:nth-child(6) {
    margin-right: 5px;

}

.state--sent {
    display: none;
}

.state--sent svg {
    transform: scale(1.25);
    margin-right: 8px;
}

.button:focus .state--default {
    position: absolute;
}

.button:focus .state--sent {
    display: flex;
}

.button:focus .state--sent span {
    opacity: 0;
    animation: slideDown 0.8s ease forwards calc(var(--i) * 0.2s);
}

.button:focus .state--sent .icon svg {
    opacity: 0;
    animation: appear 1.2s ease forwards 0.8s;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(4) rotate(-40deg);
        color: var(--primary);
        filter: blur(4px);
    }

    30% {
        opacity: 1;
        transform: scale(0.6);
        filter: blur(1px);
    }

    50% {
        opacity: 1;
        transform: scale(1.2);
        filter: blur(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}






















#keyboard {
    margin: 0;
    padding: 0;
    list-style: none;
    user-select: none;
    /* Prevent text selection */
    -webkit-user-select: none;
    /* For Safari */
    -ms-user-select: none;
}

#keyboard li {
    -webkit-tap-highlight-color: transparent;
    /* Remove highlight on tap */
    float: left;
    margin: 0 5px 5px 0;
    width: 80px;
    height: 68px;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    background: #fff;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
    box-shadow:
        2px 2px 10px rgba(0, 0, 0, 0.2),
        /* Outer shadow */
        inset 2px 2px 5px rgba(0, 0, 0, 0.1);
    /* Inner shadow */
    transition: box-shadow 0.3s ease;
    /* Smooth transition for shadow on hover */
}

#keyboard li:hover {
    position: relative;
    top: 1px;
    left: 1px;
    border-color: #e5e5e5;
    cursor: pointer;
    box-shadow:
        2px 2px 12px rgba(0, 0, 0, 0.3),
        /* Darker outer shadow on hover */
        inset 2px 2px 5px rgba(0, 0, 0, 0.2);
    /* More pronounced inner shadow on hover */
}


.capslock,
.tab,
.left-shift,
.clearl,
.switch {
    clear: left;
}

#keyboard .tab,
#keyboard .delete {
    width: 70px;
}

#keyboard .capslock {
    width: 80px;
}

#keyboard .return {
    width: 90px;
}

#keyboard .left-shift {
    width: 70px;
}

#keyboard .switch {
    width: 90px;
}

#keyboard .rightright-shift {
    width: 109px;
}

.lastitem {
    margin-right: 0;
}

.uppercase {
    text-transform: uppercase;
}

#keyboard .space {
    float: left;
    width: 556px;
}

#keyboard .switch,
#keyboard .space,
#keyboard .return {
    font-size: 16px;
}

.on {
    display: none;
}