/* Common styles for the table header */
.table-header {
  position: 'relative';
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin: 2px;
  margin-block: 5px;
  margin-bottom: 1px;
  background-color: rgba(156, 148, 189, 0.507);
  /* background-color: #E1D8F0; */
  border-radius: 5px;
  box-shadow: 0px 4px 8px white;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Styles for the order details section */
.order-details {
  display: flex;
  gap: 16px;
}

/* Styles for individual detail items */
.detail-item {
  display: flex;
  align-items: center;
  color: black;
}

/* Font size for detail item text */
.detail-item div {
  font-size: 16px;
}

/* Style for the input box */
.detail-item input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

// .transparent_gradient {
//   position: 'absolute';
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(180deg, transparent 0, black 100%);
//   pointer-events: none
// }
