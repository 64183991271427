.invoice-discount-row {
    position: relative;
    table-layout: fixed;
    min-width: 100px;
    position: relative;
}

.invoice-discount-row .parent {
    display: flex;
    min-width: 100px;
}

.invoice-discount-row .child {
    flex-grow: 0;
    flex-shrink: 0;
}

.invoice-discount-row .child:first-child {
    flex-basis: 30%;
}

.invoice-discount-row .child:last-child {
    flex-basis: 70%;
}

.invoice-discount-row .bottom-div {
    samp {
        padding-top: 6px;
        padding-left: 4px;
        font-size: 130%;
    }

    span {
        flex-grow: 3;
        padding-top: 6px;
        margin-left: 8px;
        flex-basis: 50px;
    }
}

/* Media query for small screens (max-width: 576px) */
@media (max-width: 768px) {
    .custom-table tbody td[stock-header="true"] {
        padding-left: 0px !important;
        tbody td::before {
            color: rgb(128, 125, 125) !important;
            font-weight: normal;
        }
    }

    
}

@keyframes scrollRightToLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
