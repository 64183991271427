.item-Sale-card_1 {
  background-color: rgb(214, 214, 214) !important;
  border-radius: 5px;
}

.item-Sale-card_2 {
  background-color: rgb(225, 225, 225) !important;
  border-radius: 5px;
}

.item-Sale-card_3 {
  background-color: #dfdff1f5 !important;
  border-radius: 5px;
}

.item-Sale-card_4 {
  background-color: rgb(240, 240, 240) !important;
  border-radius: 5px;
}

.custom-to-date-col {
  width: 30%;
  /* Adjust the width as needed */
}





// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++



.ag-grid-container {
  width: 100%;
  height: calc(100vh - 200px);
  /* Adjust the 200px to your desired margin or padding */
  overflow: auto;
  /* Add scrollbars if necessary */
}