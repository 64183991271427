.sales-return-row {
    position: relative;
    table-layout: fixed;
    min-width: 200px;
    position: relative;
}

.sales-return-Image-row {
    position: relative;
    table-layout: fixed;
    width: 200px;
    position: relative;
}

.sales-discount-row {
    position: relative;
    table-layout: fixed;
    min-width: 100px;
    position: relative;

    .parent {
        display: flex;
        min-width: 100px;
    }

    .child {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .child:first-child {
        flex-basis: 30%;
    }

    .child:last-child {
        flex-basis: 70%;
    }


}

.sales-rate-row {
    position: relative;
    table-layout: fixed;
    min-width: 170px;
    position: relative;

    .parent {
        display: flex;
        min-width: 100px;
    }

    .child {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .child:first-child {
        flex-basis: 50%;
        padding-right: 4px;
    }

    .child:last-child {
        flex-basis: 50%;
    }


}