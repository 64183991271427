.pricelistclass {
  padding-bottom: 0;
  padding-right: 0;
  margin-right: 9.3px;

}

.col-10 {
  margin-right: -0.9cm;
  margin: -0.6cm;


}

.col-1 {
  padding-bottom: 0;
  margin-left: 9px;
  align-items: flex-end;
  cursor: pointer;
}


#drop-downcss {
  margin-top: -37px;

}


#color {
  // width: 5.5cm;
  border: 15px;
  padding: 50px;
  margin: 12px;
  background-color: white;
  min-width: auto;
  // height: 9cm;
  min-height: auto;
  margin-left: 0cm;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  // overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  position: absolute;
  z-index: 1;
  padding-top: 0;

  display: none;


}

#Input:hover+.color {
  display: block;
}

// +++++++++++++++++++++++++++++++++++++























$border: #ddd;
$border-hover: rgb(71, 71, 71);
$bg-hover: rgba(0, 0, 0, .1);
$text: rgba(102, 102, 102, 1);
$text-hover: #000;
$ident: 30px;
$left: -($ident);
$first: #DDF3FE;
$second: #DDEBC8;
$third: #FEFCD5;



body1 {
  padding: 50px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: normal;
}

h1 {
  padding: 0 0 10px 0;
}

ol {
  margin-left: $ident;
  counter-reset: item;
}

.wtree {
  li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative;

    &:before {
      content: "";
      counter-increment: item;
      position: absolute;
      top: -10px;
      left: $left;
      border-left: 1px solid $border;
      border-bottom: 1px solid $border;
      width: $ident;
      height: 15px;
    }

    &:after {
      position: absolute;
      content: "";
      top: 5px;
      left: $left;
      border-left: 1px solid $border;
      border-top: 1px solid $border;
      width: $ident;
      height: 100%;
    }

    &:last-child:after {
      display: none;
    }

     span {
      display: block;
      border: 1px solid $border;
      padding: 10px;
      color: $text;
      text-decoration: none;

      &:before {
        // content: counters(item, ".") " ";    
      }
    }
  }
}


.wtree {
  li {
    span {

      &:hover,
      &:focus {
        color: $text-hover;
        border: 1px solid $border-hover;

        &+ol {
          li {
            span {
              color: $text-hover;
              border: 1px solid $border-hover;
            }
          }
        }
      }

      &:hover+ol,
      &:focus+ol {
        li {

          &:after,
          &:before {
            border-color: $border-hover;
          }
        }
      }
    }
  }
}

#span1 {
  background-color: $first ;
  border-radius: 7px;
}

#span2 {
  // background-color:$second ;
  border-radius: 7px;
}

li li span {
  background-color: $second
}

li li li span {
  background-color: $third
}

li li li li span {
  background-color: $first
}

.color1 {
  background-color: $first
}

.color2 {
  background-color: $second
}

.display {
  margin-right: 12px;
  font-weight: bold;
}

// input , label{
//   margin:12px 0px 20px 0px;
// }

// label {
//     padding-left: 6px;
//     padding-right: 12px;
// }

// #radio-1:checked+label ~ ol li ol li {display: none;}
// #radio-2:checked+label ~ ol li ol li ol li  {display: none;}
// #radio-3:checked+label ~ li ol li ol li {display: none;}

// .d1 {
//   // background: hotpink;
//   flex: 0 20 20px;
// }
// .d2 {
//   // background: greenyellow;
//   flex: 1;
// }
.d2 {
  // background: rgb(214, 253, 242);
  // flex: 1;
  // max-width: 30 rem;
}

.d4 {
  // background: tomato;
  // flex: 1;
}

.samp-1 {
  top: 5px;
  left: 5px;
  border-left: 1px solid $border;
  border-top: 1px solid $border;
  width: 10px;
  ;
  height: 100%;
}








.flexcontainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height:  45px;
  // flex-wrap: wrap;
}

.flexitem-1 {
  flex-grow: 4;
  flex: 50%;
  flex-wrap: nowrap;
  border-radius: 7px;
}

.flexitem-2 {
  // flex-grow:3;
  // max-width:  200px;
  // flex-basis: 200px;
  flex: 20%;
}

.flexitem-3 {
  flex-grow: 1;
  // flex-basis: 40px;
  max-width: 40px;
  // flex: 20%;
}

.flexitem-4 {
  // flex-basis: 20px;
  // flex-grow:1;
  // max-width: 30px;
  // flex: 10%;
  text-align: center;
  //  margin-top: 10px; 
  //  padding-top: 49px;
  //  padding-left: 2px;

}

