.table-container {
    min-height: 140px;
    /* Set the minimum height for the container */
    max-height: 400px;
    /* Set the maximum height for the container */
    overflow-y: auto;
    /* Enable vertical scrolling */
}


.hidden-row {
    display: none;
}