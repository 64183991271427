 #c__pagination {
     list-style: none;
     display: flex;
     justify-content: flex-end;

 }

 #c__pagination li {
     display: inline-block;
     padding: 5px 10px;
     margin: 0 2px;
     border-radius: 35%;
     cursor: pointer;
     transition: background-color 0.3s ease;
 }

 #c__pagination li:hover {
     background-color: #ddd;
 }

 #c__pagination li.active {
     background-color: rgb(72, 90, 247);
     color: #fff;
 }





 ._row-blink {
     animation: blink-animation 2s 3;
     /* 2 seconds duration, 2 iterations */
 }

 @keyframes blink-animation {

     0%,
     100% {
         background-color: transparent;
     }

     50% {
         //   background-color: #511db329; /* Soft purple color */
         background-color: #9dadf09e;
         /* Pale yellow color */
     }
 }

 ._deleted-Row {
     text-decoration: line-through !important;
 }




 .react-bootstrap-table th {
     white-space: nowrap;
     text-overflow: ellipsis;

 }


























 /* Common styles for both desktop and mobile */
 .custom-table {
     width: 100%;
     border-collapse: collapse !important;
     box-shadow: 2px 2px 7px 0px #a9a9a994;

     thead {
         box-shadow: 0px 3px #a9a9a970 !important;
     }

     tr:nth-child(even) {
         background-color: #f2f2f25c;
     }

     thead th {
         padding: 12px;
         border: 1px solid #ddd;

     }

     tbody td {
         padding: 10px;
         border: 1px solid #ddd;
     }
 }

 @media (max-width: 768px) {
     .custom-table {
         width: 100%;
         border-collapse: collapse;


         th td {
             display: block;
             text-align: left;
             border: 1px solid #ddd;
             padding: 8px;
             /* Adjust as needed */
         }

         thead {
             display: none;
         }

         tbody tr {
             display: flex;
             flex-direction: column;
             margin-bottom: 10px;
             border: 1px solid #ddd;
             border-radius: 5px;
             background-color: #f8f8f8;
         }

         tbody td {
             border: none;
             border-bottom: 1px solid #ddd;
             position: relative;
             padding-left: 50%;
             text-align: left;
             background-color: #f8f8f8;
         }

         tbody td:before {
             content: attr(data-label);
             font-weight: bold;
             position: absolute;
             left: 5px;
             top: 50%;
             transform: translateY(-50%);
             white-space: nowrap;
         }

         tbody td.selection-cell:before {
             content: "Select";
             font-weight: bold;
             position: absolute;
             left: 5px;
             top: 50%;
             transform: translateY(-50%);
             white-space: nowrap;
         }

         tbody td[sticky-col="true"] {
             position: sticky;
             left: 0;
             z-index: 2;
             top: 0;
             background-color: rgb(233, 233, 239);
         }
     }
 }

 // ========================================================================