// * {
//     box-sizing: border-box;
//   }
  
//    body {
//     font: 16px Arial;  
//   }
   

  /*the container must be positioned relative:*/
  // .autocomplete {
  //   position: relative;
  //   display: inline-block;
    
  // }
  
  // .input {
  //   border: 1px solid transparent;
  //   background-color: #f1f1f1;
  //   padding: 10px;
  //   font-size: 16px;
  // }
  
  // input[type=text] {
  //   background-color: #250909;
  //   width: 100%;
  // } 
  
  // input[type=submit] {
  //   background-color: DodgerBlue;
  //   color: #fff;
  //   cursor: pointer;
  // }
  
  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    background-color:white;
    
  }
  
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff; 
    border-bottom: 1px solid #d4d4d4; 
  
  }
  
  /*when hovering an item:*/
  .autocomplete-items div:hover {
    background-color: #e9e9e9; 
  }
  
  // /*when navigating through the items using the arrow keys:*/
  .autocomplete-active {
    background-color:  #e9e9e9!important; 
    
  }