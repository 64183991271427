  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;

  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;

  }

  /*when hovering an item:*/
  .autocomplete-items div:hover {
    background-color: #e9e9e9;
  }

  // /*when navigating through the items using the arrow keys:*/
  .autocomplete-active {
    background-color: #e9e9e9 !important;

  }


  #ActionBtn {
    display: flex;
    // float: right;
    gap: 7px;
    justify-content: right;
    // padding-right: 60px;
    min-width: 10%;
  }