/* Default button styles */
.btn-edit,
.btn-delete {
    display: inline-block;
    text-align: center;
}

/* Media query for smaller screens */
@media (max-width: 768px) {

    .btn-edit,
    .btn-delete {
        display: block;
        /* Change to a block-level element */
        width: 100%;
        /* Take up full width of the container */
        margin-bottom: 10px;
        /* Add spacing between buttons */
    }
}

/* Style for selected row */
tr.selected {
    background-color: rgb(209, 235, 245);
    /* Change to your desired background color */
    color: #000;
    /* Change to your desired text color */
}

.selected {
    background-color: lightblue; /* Set the background color for selected rows */
    /* Add any other styling you want for selected rows */
  }