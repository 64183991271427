/* YourComponent.css */

// @import '../../assets/scss/variables';

.change-party-contener {
  cursor: pointer !important;
  position: relative;
  background-color: #383440;
  z-index: 2;
  /* Set a higher z-index */
}

.party-label-wapper {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-inline: 25px;
  /* padding-block: 5px; */
  color: #ffbf53a6;
  font-size: 18;
  margin-bottom: -10px;
  margin-top: 2px;
  border: solid;
  border-color: #f2f2f28a;
  /* A light color close to white */
  border-width: 0.5px;
  /* Reduce border width for a lighter appearance */

}

.party-label {
  cursor: pointer;

  /* font-size: medium; */
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;

  max-width: 190px;
  /* Adjust the maximum width according to your layout */
  overflow: hidden;
}

.party-label-name {
  display: block;
  cursor: pointer !important;
  margin-bottom: 0.5rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2px;
}

.mini-drawer {
  position: fixed;
  top: 61px;
  left: 254px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 992px) {
    left: 60px;
    margin-left: 11px;
    
  }





}

.c_modal-content {
  width: 350px;
  background-color: white;
  padding: 5px;
  padding-bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}